<style scoped>
.tree_tool {
  background-color: white;
  border-bottom: 1px solid #cccccc;
  font-size: 18px;
  padding: 5px 10px;
}

.breadcrumb {
  margin: 10px 0px;
  padding: 10px 0px;
  background-color: white;
}

.treeBox {
  overflow: auto;
  height: 480px !important;
  width: 562px !important;
}

.scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

</style>
<template>
  <div>
    <el-row :gutter="20">
      <el-col>
        <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
          <el-breadcrumb-item>角色数量：</el-breadcrumb-item>
          <el-breadcrumb-item>{{ page.total }}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="grid-content bg-purple">
          <div class="tree_tool">
            <el-button type="primary" icon="el-icon-plus" @click="userdigShow = true">添加</el-button>
            <el-button type="success" icon="el-icon-refresh" @click="initPageData">刷新</el-button>
          </div>
          <el-table
              v-loading="showLoading"
              :data="page.list"
              element-loading-text="拼命加载中"
              border
              style="width: 100%; height:50%"
              @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="35"></el-table-column>
            <el-table-column type="index" label="序号" width="50"></el-table-column>
            <el-table-column prop="isdefault" label="默认" width="50">
              <template #default="scope">
                <i :class="1==scope.row.isdefault?'el-icon-circle-check':'el-icon-circle-close'"
                   :style="{ color: DS[scope.row.isdefault] }"></i>
              </template>
            </el-table-column>
<!--            <el-table-column prop="type" label="角色组" width="100">-->
<!--              <template #default="scope"> {{ ROLETYPES[scope.row.type].name }}</template>-->
<!--            </el-table-column>-->
            <el-table-column prop="name" label="角色名"></el-table-column>
            <el-table-column prop="remark" label="备注"></el-table-column>
            <el-table-column label="操作" conter width="300">
              <template #default="scope">
                <span v-if="scope.row.cid==CID||10000==RID">
                     <el-button type="text" icon="el-icon-edit"
                                @click="handle_update(scope.$index, scope.row)">编辑  </el-button>
                     <el-button type="text" icon="el-icon-key" class="yellow"
                                @click="editorAuth(scope.row)">授权 </el-button>
                     <el-button type="text" icon="el-icon-delete" class="red"
                                @click="handle_del(scope.$index, scope.row)">删除 </el-button>
                </span>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              :current-page="page.pageNum"
              :page-size="page.pageSize"
              layout="total, prev, pager, next, jumper"
              :total="page.total"
              @current-change="devlogChange"
          ></el-pagination>
        </div>
      </el-col>
    </el-row>

    <!-- 用户对话框-->
    <el-dialog title="角色信息" :close-on-click-modal="false" width="600px" v-model="userdigShow"
               @close="closeDialog">
      <el-form ref="role" :model="role" :rules="rolerules">
        <el-form-item label="角色组:" label-width="100px" prop="type">
          <el-select v-model="role.type" placeholder="请选择角色类型" style="width: 100%">
            <el-option
                v-for="item in ROLETYPES"
                :key="item.value"
                :label="item.name"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色名" label-width="100px" prop="name">
          <el-input v-model="role.name" @blur="matchRoleName"></el-input>
        </el-form-item>
        <el-form-item label="系统默认:" label-width="100px" v-if="1000==RID">
          <el-switch v-model="role.isdefault" :active-value="1" :inactive-value="0" active-color="#13ce66"
                     inactive-color="#ff4949" active-text="是" inactive-text="否"></el-switch>
        </el-form-item>
        <el-form-item label="角色备注" label-width="100px" prop="remark">
          <el-input v-model="role.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="userdigShow = false">取 消</el-button>
        <el-button type="primary" @click="saveRole('role')">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 权限树状图-->
    <el-dialog
        title="选择权限"
        :close-on-click-modal="false"
        width="600px"
        v-model="truedigShow"
        @open="openDialogTree"
        @close="closeDialogTree"
    >
      <div class="treeBox">
        <el-tree
            ref="tree"
            default-expand-all
            lazy
            highlight-current
            :expand-on-click-node="false"
            :check-strictly="true"
            show-checkbox
            node-key="id"
            :load="loadTreeNode"
            :props="defaultProps"
            @node-click="setCuttTreeNode"
        >
        </el-tree>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click=" closeDialog();">取 消</el-button>
        <el-button type="primary" @click="saveAuth">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import roleApi from '../../api/sys/api_role'
import menuApi from '../../api/base/api_menu'
import COMM from "../../api/base/api_comm";

export default {
  name: 'aclmanage',
  data() {
    return {
      CID: -1, RID: -1,
      auth: {},
      ROLETYPES: [],
      DS: ['#CCCCCC', '#30d351'],

      page: {list: [], pageNum: 1, pageSize: 10, total: 0},
      role: {title: '角色信息'},
      showLoading: false,
      userdigShow: false,
      truedigShow: false,
      choseRow: {},
      loadNode: [],
      treeMode: {},
      isMatch: true,
      defaultProps: {children: 'subs', label: 'title'},
      rolerules: {
        type: [{required: true, message: '请输入角色名', trigger: 'blur'}],
        name: [
          {required: true, message: '请输入角色名', trigger: 'blur'},
          {min: 2, max: 15, message: '长度在 2 到 15 个字符', trigger: 'blur'}
        ]
      }
    }
  }
  ,
  mounted() {
    this.init()
  }
  ,
  methods: {
    init() {
      this.auth = this.$getAuth();
      this.treeMode = this.$refs.tree
      this.initTree()
      this.init_base();
      this.initPageData()
    },
    async init_base() {
      let roletypse = {};
      let user = this.$store.state.cuttuser;
      this.RID = user.rid;
      this.CID = user.cid;
      let res = await COMM.getObjType({"type": "3"});
      for (let rt of res) {
        if (this.RID == 10000 ||  rt.value<10000) {
          roletypse[rt.value] = rt
        }
      }
      this.ROLETYPES = roletypse;
    },
    saveAuth() {
      let choseIds = this.$refs.tree.getCheckedKeys()
      let nsel = this.$refs.tree.getHalfCheckedKeys()
      if (nsel.length > 0) {
        for (let nid of nsel) {
          if (!choseIds.indexOf(nid) > -1) {
            choseIds.push(nid)
          }
        }
      }
      if (choseIds == null || choseIds.length == 0) {
        this.$message.error('请选择需要绑定的菜单！')
        return
      }
      menuApi.bindMenu({
        roleId: this.choseRow.id,
        oids: this.choseRow.menuIds,
        menuIds: choseIds
      }).then(res => {
        if (res.code == 200) {
          this.$message.success('绑定成功')
          this.initPageData()
        } else {
          this.$message.error('绑定失败！')
        }
      })
      this.$refs.tree.setCheckedKeys([])
      this.choseRow = {}
      this.truedigShow = false
    }
    ,
    editorAuth(row) {
      this.truedigShow = true
      this.choseRow = row
    },
    loadTreeNode(node, resolve) {
      if (!node.data) {
        return resolve(this.loadNode)
      }
      return node.data.subs.length > 0 ? resolve(node.data.subs) : resolve([])
    }
    ,
    setCuttTreeNode(data, node, e) {
    },
    initTree(node, resolve) {
      menuApi.getSYSMenu().then(res => {
        if (res.code == 200) {
          let nodes = res.data.subs
          this.loadNode = nodes
        }
      })
    }
    ,
    // ========================================================================用户信息========================================================================
    handleSelectionChange(val) {
    }
    ,
    closeDialog() {
      this.role = {}
      this.isMatch = true
      this.truedigShow = false
    }
    ,
    closeDialogTree() {
    }
    ,
    openDialogTree() {
      this.$nextTick(_ => {
        if (this.choseRow.menuIds.length > 0) {
          this.$refs.tree.setCheckedKeys(this.choseRow.menuIds)
        }
      })
    }
    ,
    initPageData() {
      this.showLoading = true
      roleApi.getRoleList({pageNum: this.page.pageNum, pageSize: this.page.pageSize}).then(res => {
        if (res.code == 200) {
          this.page.total = res.pageData.total
          this.page.list = res.pageData.list
        } else {
          this.$message.error('获取角色信息失败！')
        }
        this.showLoading = false
      })
    }
    ,
    handle_update(index, row) {
      this.userdigShow = true
      this.role = row
    }
    ,
    handle_del(index, row) {
      this.$confirm('此操作将删除[' + row.roleName + '] 角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            // var ids=[].push(row)
            roleApi.deleteRole({roleIds: row.id}).then(res => {
              if (res.code == 200) {
                this.$message.success('删除成功！')
                this.initPageData()
              } else {
                this.$message.error(res.msg)
              }
            })
          })
          .catch(() => {
          })
    }
    ,
    handle_respwd(index, row) {
      this.userInfo = {id: row.id, I: 2, username: row.username}
      this.respwddigShow = true
    }
    ,
    devlogChange(pageNum) {
      this.page.pageNum = pageNum
      this.initPageData()
    }
    ,
    matchRoleName() {
      if (this.role.name) {
        roleApi.matchRoleName({roleName: this.role.name}).then(res => {
          if (res.code == 200) {
            this.$message.success('角色名可使用！')
            this.isMatch = true
          } else {
            this.$message.error('角色名已重复！')
            this.isMatch = false
          }
        })
      } else {
        this.$message.error('请输入角色名')
        this.isMatch = false
      }
    }
    ,
    async saveRole(formName) {
      if (this.role.name && this.isMatch) {
        let param = this.role
        let res = await (this.role.id ? roleApi.updateRole(param) : roleApi.addRole(param))
        if (res.code == 200) {
          this.$message.success('操作成功！')
          this.role = {}
          this.initPageData()
          this.userdigShow = false
        } else {
          this.$message.error('操作失败！')
        }
      }
    }
  }
}
</script>
