/**
 * Created by bootdo.
 * 用户相关api
 */
import * as API from '../../utils/request';

export default {
    //获取角色信息
    getRoleList: params => {return API.GET('role/getRoleList', params)},
    //修改角色信息
    addRole: params => {return API.POST('role/sys/addRole', params)},
    //修改角色信息
    updateRole: params => {return API.POST('role/sys/updateRole', params)},
    //删除角色信息
    deleteRole: params =>{return API.POSTARRAY('role/sys/deleteRole',params)},
    //删除角色信息
    matchRoleName: params =>{return API.GET('role/matchRoleName',params)},
    //数据权限
    datascope: params => {return API.GET('role/datascope', params)},
}
